var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forum-details" },
    [
      _c("div", { staticClass: "details-article" }, [
        _c("div", { staticClass: "article-left" }, [
          _c("div", { staticClass: "item" }, [
            _vm._v("\n        喜欢:\n        "),
            _vm.allLikeList.data.length !== 0
              ? _c("span", [_vm._v(_vm._s(_vm.allLikeList.data.length))])
              : _c("span", [_vm._v("0")])
          ]),
          _c("div", { staticClass: "item" }, [
            _vm._v("\n        回复:\n        "),
            _vm.returnCardList.length
              ? _c("span", [_vm._v(_vm._s(_vm.returnCardList.length))])
              : _c("span", [_vm._v("0")])
          ]),
          _vm.deail.username
            ? _c("div", { staticClass: "item" }, [
                _vm._v("楼主:" + _vm._s(_vm.deail.username))
              ])
            : _vm._e(),
          _c("div", { staticClass: "head-portrait" }, [
            _vm.deail.file
              ? _c("div", {
                  staticClass: "bg",
                  style: {
                    backgroundImage: "url(" + _vm.deail.file + ")",
                    backgroundSize: "cover"
                  }
                })
              : _vm._e(),
            _c("div", {
              staticClass: "position",
              staticStyle: { "padding-bottom": "100%" }
            })
          ])
        ]),
        _c(
          "div",
          { staticClass: "article-right" },
          [
            _c("div", { staticClass: "top" }, [
              _vm.deail.title
                ? _c("div", { staticClass: "top-title" }, [
                    _c("p", [_vm._v(_vm._s(_vm.deail.title))]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.copys,
                            expression: "copys",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success"
                          }
                        ]
                      },
                      [_vm._v("[复制标题]")]
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "top-cz" }, [
                _c("div", { staticClass: "jump" }, [
                  _vm.nexts
                    ? _c(
                        "div",
                        {
                          staticClass: "before",
                          on: {
                            click: function($event) {
                              return _vm.jump(_vm.nexts)
                            }
                          }
                        },
                        [_c("a-icon", { attrs: { type: "arrow-left" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.ups
                    ? _c(
                        "div",
                        {
                          staticClass: "after",
                          on: {
                            click: function($event) {
                              return _vm.jump(_vm.ups)
                            }
                          }
                        },
                        [_c("a-icon", { attrs: { type: "arrow-right" } })],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "date" }, [
              _c("p", [
                _vm._v("\n          楼主发表于\n          "),
                _vm.deail.create_time
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            .$moment(+_vm.deail.create_time)
                            .format("YYYY-MM-DD HH:mm")
                        )
                      )
                    ])
                  : _vm._e(),
                _c("span", [_vm._v("|")]),
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.btnbig(_vm.deail.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.isbig ? "取消看大图" : "只看大图"))]
                )
              ])
            ]),
            _vm.isbig
              ? _c(
                  "div",
                  { staticClass: "content", attrs: { id: "content111" } },
                  _vm._l(_vm.big, function(item, index) {
                    return _c("p", { key: index }, [
                      _c("img", { attrs: { src: item } })
                    ])
                  }),
                  0
                )
              : [
                  _vm.deail.content
                    ? _c("div", {
                        staticClass: "content",
                        attrs: { id: "content111" },
                        domProps: { innerHTML: _vm._s(_vm.deail.content) }
                      })
                    : _vm._e()
                ],
            _vm._m(0),
            _c(
              "div",
              { staticClass: "score" },
              [
                _vm.newLikeList.length !== 0
                  ? _c(
                      "div",
                      {
                        staticClass: "score-look",
                        on: { click: _vm.scoreBtn }
                      },
                      [
                        _c("div", { staticClass: "bg" }, [
                          _vm._v(_vm._s(_vm.newLikeList.length))
                        ]),
                        _c("div", { staticClass: "position" }, [
                          _vm._v("查看全部评分")
                        ])
                      ]
                    )
                  : _vm._e(),
                _c("allScore", {
                  attrs: { allLikeList: _vm.allLikeList },
                  model: {
                    value: _vm.isScore,
                    callback: function($$v) {
                      _vm.isScore = $$v
                    },
                    expression: "isScore"
                  }
                }),
                _vm.newLikeList.length !== 0
                  ? _c(
                      "div",
                      { staticClass: "score-list" },
                      _vm._l(_vm.newLikeList, function(item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "item" },
                          [
                            _c("div", { staticClass: "item-bg" }, [
                              _c("div", {
                                staticClass: "img",
                                style: {
                                  backgroundImage: "url(" + item.file + ")",
                                  backgroundSize: "cover"
                                }
                              }),
                              _c("div", { staticClass: "position" }, [
                                _vm._v(_vm._s(item.username))
                              ])
                            ]),
                            _c("div")
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "operation" }, [
              _c(
                "div",
                {
                  style: _vm.starStyle,
                  on: {
                    click: function($event) {
                      return _vm.btnStar(_vm.deail.id)
                    }
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "star", theme: "filled" } }),
                  _vm._v("收藏\n        ")
                ],
                1
              ),
              _c("div", [
                _c(
                  "a",
                  { attrs: { href: "#share-2" } },
                  [
                    _c("a-icon", { attrs: { type: "share-alt" } }),
                    _vm._v("分享\n          ")
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  style: _vm.likeStyle,
                  on: {
                    click: function($event) {
                      return _vm.btnHeart(_vm.deail.id)
                    }
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "heart", theme: "filled" } }),
                  _vm._v("喜欢\n        ")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "related" }, [
              _c("div", { staticClass: "related-wen" }, [_vm._v("相关文章")]),
              _vm.relatedList.length !== 0
                ? _c(
                    "div",
                    { staticClass: "related-list" },
                    _vm._l(_vm.relatedList, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          on: {
                            click: function($event) {
                              return _vm.TZ(item.id)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "related-list" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          width: "100%",
                          "line-height": "2"
                        }
                      },
                      [_vm._v("暂无相关帖子")]
                    )
                  ])
            ]),
            _c("div", { staticClass: "reply" }, [
              _vm.info
                ? _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.replyBtn(1)
                        }
                      }
                    },
                    [
                      _vm.info.id !== _vm.deail.uid
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "profile" } }),
                              _vm._v("回复\n          ")
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c("div", { on: { click: _vm.btnreport } }, [_vm._v("举报")])
            ])
          ],
          2
        )
      ]),
      _vm._l(_vm.returnCardList, function(item) {
        return _c("div", { key: item.id, staticClass: "details-article" }, [
          _c("div", { staticClass: "article-left" }, [
            _c("div", { staticClass: "item" }, [
              _vm._v(_vm._s(item.username) + " 回复:")
            ]),
            _c("div", { staticClass: "head-portrait" }, [
              _c("div", {
                staticClass: "bg",
                style: {
                  backgroundImage: "url(" + item.file + ")",
                  backgroundSize: "cover"
                }
              }),
              _c("div", {
                staticClass: "position",
                staticStyle: { "padding-bottom": "100%" }
              })
            ])
          ]),
          _c("div", { staticClass: "article-right" }, [
            _c("div", { staticStyle: { width: "100%", height: "10px" } }),
            _c("div", { staticStyle: { "min-height": "200px" } }, [
              _c("div", { staticClass: "date" }, [
                _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(item.username) +
                      "发表于 " +
                      _vm._s(
                        _vm
                          .$moment(+item.create_time)
                          .format("YYYY-MM-DD HH:mm")
                      ) +
                      "\n            "
                  ),
                  _c("span", [_vm._v("|")]),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onlyZ(item.username)
                        }
                      }
                    },
                    [_vm._v("只看该作者")]
                  )
                ])
              ]),
              _c("div", {
                staticClass: "hf-content",
                domProps: { innerHTML: _vm._s(item.content) }
              }),
              item.lzcontent !== "暂无留言"
                ? _c("div", { staticClass: "date" }, [
                    _c("p", [
                      _c("span", { staticClass: "name" }, [
                        _vm._v("楼主:" + _vm._s(item.lzuser))
                      ]),
                      _vm._v(" 回复\n            "),
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.username))
                      ]),
                      _vm._v(
                        "\n            于 " +
                          _vm._s(
                            _vm.$moment(+item.lztime).format("YYYY-MM-DD HH:mm")
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              item.lzcontent !== "暂无留言"
                ? _c("div", {
                    staticClass: "hf-content",
                    domProps: { innerHTML: _vm._s(item.lzcontent) }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "reply" }, [
              _vm.info
                ? _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.replyBtn(2, item.id, item.lzuser)
                        }
                      }
                    },
                    [
                      _vm.info.username == _vm.deail.username
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "profile" } }),
                              _vm._v("回复\n          ")
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c("div", { on: { click: _vm.btnreport } }, [_vm._v("举报")])
            ])
          ])
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "回复", visible: _vm.isReply },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c("VueUeditorWrap", {
            attrs: { config: _vm.myConfig },
            on: { ready: _vm.ready },
            model: {
              value: _vm.textareaValue,
              callback: function($$v) {
                _vm.textareaValue = $$v
              },
              expression: "textareaValue"
            }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "举报", visible: _vm.isreport },
          on: { ok: _vm.handleOkReport, cancel: _vm.handleCancelReport }
        },
        [
          _c("a-textarea", {
            attrs: { rows: 4 },
            model: {
              value: _vm.textareaValueReport,
              callback: function($$v) {
                _vm.textareaValueReport = $$v
              },
              expression: "textareaValueReport"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "30%" },
        attrs: { src: "/assets/images/pf.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }