<template>
  <div class="forum-details">
    <div class="details-article">
      <div class="article-left">
        <div class="item">
          喜欢:
          <span v-if="allLikeList.data.length!==0">{{allLikeList.data.length}}</span>
          <span v-else>0</span>
        </div>
        <div class="item">
          回复:
          <span v-if="returnCardList.length">{{returnCardList.length}}</span>
          <span v-else>0</span>
        </div>
        <div class="item" v-if="deail.username">楼主:{{deail.username}}</div>
        <div class="head-portrait">
          <div
            class="bg"
            v-if="deail.file"
            :style="{ backgroundImage: 'url(' + deail.file + ')',backgroundSize:'cover'}"
          ></div>
          <div class="position" style="padding-bottom:100%"></div>
        </div>
      </div>
      <div class="article-right">
        <div class="top">
          <div class="top-title" v-if="deail.title">
            <p>{{deail.title}}</p>
            <span v-clipboard:copy="copys" v-clipboard:success="onCopy">[复制标题]</span>
          </div>
          <div class="top-cz">
            <!-- <div class="print">
              <a-icon type="printer" />
            </div>-->
            <div class="jump">
              <div class="before" @click="jump(nexts)" v-if="nexts">
                <a-icon type="arrow-left" />
              </div>
              <div class="after" @click="jump(ups)" v-if="ups">
                <a-icon type="arrow-right" />
              </div>
            </div>
          </div>
        </div>
        <div class="date">
          <p>
            楼主发表于
            <span
              v-if="deail.create_time"
            >{{ $moment(+deail.create_time).format('YYYY-MM-DD HH:mm') }}</span>
            <span>|</span>
            <span @click="btnbig(deail.id)">{{isbig?'取消看大图':'只看大图'}}</span>
          </p>
        </div>
        <div class="content" id="content111" v-if="isbig">
          <p v-for="(item, index) in big" :key="index">
            <img :src="item" />
          </p>
        </div>
        <template v-else>
          <div class="content" id="content111" v-if="deail.content" v-html="deail.content"></div>
        </template>
        <div>
          <img src="/assets/images/pf.png" style="width: 30%;" />
        </div>
        <div class="score">
          <div class="score-look" @click="scoreBtn" v-if="newLikeList.length!==0">
            <div class="bg">{{newLikeList.length}}</div>
            <div class="position">查看全部评分</div>
          </div>
          <allScore v-model="isScore" :allLikeList="allLikeList" />
          <div class="score-list" v-if="newLikeList.length!==0">
            <div class="item" v-for="item in newLikeList" :key="item.id">
              <div class="item-bg">
                <div
                  class="img"
                  :style="{ backgroundImage: 'url(' + item.file + ')',backgroundSize:'cover'}"
                ></div>
                <div class="position">{{item.username}}</div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="operation">
          <div @click="btnStar(deail.id)" :style="starStyle">
            <a-icon type="star" theme="filled" />收藏
          </div>
          <div>
            <a href="#share-2">
              <a-icon type="share-alt" />分享
            </a>
          </div>
          <div @click="btnHeart(deail.id)" :style="likeStyle">
            <a-icon type="heart" theme="filled" />喜欢
          </div>
        </div>
        <div class="related">
          <div class="related-wen">相关文章</div>
          <div class="related-list" v-if="relatedList.length!==0">
            <div v-for="item in relatedList" :key="item.id" @click="TZ(item.id)">{{item.title}}</div>
          </div>
          <div class="related-list" v-else>
            <p style="text-align:center;width: 100%;line-height: 2;">暂无相关帖子</p>
          </div>
        </div>
        <div class="reply">
          <div @click="replyBtn(1)" v-if="info">
            <div v-if="info.id!==deail.uid">
              <a-icon type="profile" />回复
            </div>
          </div>
          <div @click="btnreport">举报</div>
        </div>
      </div>
    </div>
    <div class="details-article" v-for="item in returnCardList" :key="item.id">
      <div class="article-left">
        <div class="item">{{item.username}} 回复:</div>
        <div class="head-portrait">
          <div
            class="bg"
            :style="{ backgroundImage: 'url(' + item.file + ')',backgroundSize:'cover'}"
          ></div>
          <div class="position" style="padding-bottom:100%"></div>
        </div>
      </div>
      <div class="article-right">
        <div style="width:100%;height:10px"></div>
        <div style="min-height:200px">
          <div class="date">
            <p>
              {{item.username}}发表于 {{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}
              <span>|</span>
              <span @click="onlyZ(item.username)">只看该作者</span>
            </p>
          </div>
          <div class="hf-content" v-html="item.content"></div>
          <div class="date" v-if="item.lzcontent!=='暂无留言'">
            <p>
              <span class="name">楼主:{{item.lzuser}}</span> 回复
              <span class="name">{{item.username}}</span>
              于 {{ $moment(+item.lztime).format('YYYY-MM-DD HH:mm') }}
            </p>
          </div>
          <div class="hf-content" v-if="item.lzcontent!=='暂无留言'" v-html="item.lzcontent"></div>
        </div>
        <div class="reply">
          <div @click="replyBtn(2,item.id,item.lzuser)" v-if="info">
            <div v-if="info.username==deail.username">
              <a-icon type="profile" />回复
            </div>
          </div>
          <div @click="btnreport">举报</div>
        </div>
      </div>
    </div>
    <a-modal title="回复" :visible="isReply" @ok="handleOk" @cancel="handleCancel">
      <VueUeditorWrap v-model="textareaValue" :config="myConfig" @ready="ready" />
    </a-modal>
    <a-modal title="举报" :visible="isreport" @ok="handleOkReport" @cancel="handleCancelReport">
      <a-textarea :rows="4" v-model="textareaValueReport" />
    </a-modal>
  </div>
</template>
<script>
import allScore from './componets/allScore';
import VueUeditorWrap from 'vue-ueditor-wrap';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    allScore,
    VueUeditorWrap,
  },
  data() {
    return {
      id: '',
      num: 0,
      isScore: false,
      isReply: false,
      deail: {
        id: '',
      },
      copys: '222222',
      like: 0,
      shouc: 0,
      textareaValue: '',
      newLikeList: [],
      allLikeList: {
        data: [],
      },
      relatedList: [],
      returnCardList: [],
      isreport: false,
      textareaValueReport: '',
      review_id: '',
      nexts: '',
      ups: '',
      only: true,
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: 'http://jiakucheng.avik-bj.com/php/controller.php?action=config',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/static/UEditor/',
      },
      big: [],
      isbig: false,
    };
  },
  watch: {
    $route: function(to, from) {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      this.id = to.params.id;
      this.getDeail();
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getDeail();
  },
  computed: {
    ...mapState('user', ['info']),
    starStyle() {
      return this.shouc === 1 ? { color: '#ffcc00' } : '';
    },
    likeStyle() {
      return this.like === 1 ? { color: '#ffcc00' } : '';
    },
  },
  methods: {
    btnbig(id) {
      this.isbig = !this.isbig;
      if (this.big) {
        return this.$request(Apis.forum.DYTUPIANS, { data: { id: id } }).then(data => {
          this.big = data.data.dytp;
        });
      }
    },
    //只看该作者
    onlyZ(name) {
      let onlyList = [];

      this.returnCardList.forEach(item => {
        if (item.username == name) {
          onlyList.push(item);
        }
      });
      this.returnCardList = onlyList;
    },
    ready(editorInstance) {
      console.log(`编辑器实例${editorInstance.key}: `, editorInstance);
    },
    onCopy(e) {
      this.$message.success('复制成功');
    },
    jump(id) {
      this.$router.push('/forum/details/' + id);
    },
    scoreBtn() {
      this.isScore = true;
    },
    replyBtn(num, id, lzuser) {
      if (num === 2) {
        if (lzuser !== '暂无楼主') {
          this.$message.warning('楼主只能回复游客此评论一次');
          return;
        }
      }
      this.isReply = true;
      this.num = num;
      this.review_id = id;
    },
    handleOk(e) {
      this.isReply = false;
      if (_.isEmpty(this.info)) {
        this.$message.warning('请您先去登录');
        return;
      }
      if (this.info.shenhe === 0 || this.info.shenhe === 2) {
        this.$message.warning('后台正在审核您的账号，请等待');
        return;
      }
      if (_.isEmpty(this.deail)) {
        return;
      }
      if (this.num === 1) {
        return this.$request(Apis.forum.REVIEWS, {
          data: {
            post_id: this.deail.id,
            token: this.info.token,
            content: this.textareaValue,
          },
        })
          .then(data => {
            this.getReturnCard();
            this.$message.success('评论成功');
            this.isReply = false;
            this.textareaValue = '';
          })
          .catch(e => {})
          .finally(() => {});
      } else if (this.num === 2) {
        return this.$request(Apis.forum.WORDS, {
          data: {
            review_id: this.review_id,
            token: this.info.token,
            word_author_id: this.deail.review_user_id,
            word_content: this.textareaValue,
          },
        })
          .then(data => {
            this.getReturnCard();
            this.$message.success('评论成功');
            this.isReply = false;
            this.textareaValue = '';
            if (data.data.status !== 400) {
              this.$message.warning(data.data.msg);
            }
          })
          .catch(e => {})
          .finally(() => {});
      }
    },
    handleCancel(e) {
      this.isReply = false;
    },
    //详情
    getDeail() {
      return this.$request(Apis.forum.POSTDEAIL, { data: { id: this.id } }).then(data => {
        this.deail = data.data.data;
        this.copys = data.data.data.title;
        this.nexts = data.data.nexts;
        this.ups = data.data.ups;
        this.getNlike();
        this.getStar();
        this.getHeart();
        this.getrelated(this.deail);
        this.getReturnCard();

        let Hwidth = $('#content111').width();
        let imdwidth = $('#content111 p img').width();
        if (imdwidth >= Hwidth) {
          $('#content111 p img').css('width', '100%');
        }
      });
    },
    //点赞用户
    getNlike() {
      if (_.isEmpty(this.deail)) {
        return;
      }
      return this.$request(Apis.forum.XGJIFEN, { data: { id: this.deail.id } })
        .then(data => {
          this.newLikeList = data.data.data;

          this.getallLike();
        })
        .catch(e => {})
        .finally(() => {});
    },
    getallLike() {
      if (_.isEmpty(this.deail)) {
        return;
      }
      return this.$request(Apis.forum.XGPf, { data: { id: this.deail.id } })
        .then(data => {
          this.allLikeList = data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
    //获取收藏
    getStar() {
      if (_.isEmpty(this.info)) {
        return;
      }
      if (_.isEmpty(this.deail)) {
        return;
      }
      return this.$request(Apis.forum.PDSC, { data: { id: this.deail.id, token: this.info.token } })
        .then(data => {
          this.shouc = data.data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
    //获取点赞
    getHeart() {
      if (_.isEmpty(this.info)) {
        return;
      }
      if (_.isEmpty(this.deail)) {
        return;
      }
      return this.$request(Apis.forum.PDDZ, { data: { id: this.deail.id, token: this.info.token } })
        .then(data => {
          this.like = data.data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
    btnStar() {
      if (_.isEmpty(this.info)) {
        this.$message.warning('请您先去登录');
        return;
      }

      if (this.shouc === 0) {
        return this.$request(Apis.forum.STAR, { data: { token: this.info.token, post_id: this.id } }).then(data => {
          this.shouc = 1;
          this.$message.success('收藏成功');
        });
      } else if (this.shouc === 1) {
        return this.$request(Apis.forum.QUSTAR, { data: { token: this.info.token, post_id: this.id } }).then(data => {
          this.shouc = 0;
          this.$message.success('取消收藏成功');
        });
      }
    },
    btnHeart() {
      if (_.isEmpty(this.info)) {
        this.$message.warning('请您先去登录');
        return;
      }
      return this.$request(Apis.forum.LIKES, { data: { token: this.info.token, post_id: this.id } }).then(data => {
        this.like = 1;
        this.$message.success('点赞成功');
        if (data.data.status == 202) {
          this.$message.success(data.data.msg);
        }
      });
    },
    //相关文章
    getrelated(deail) {
      return this.$request(Apis.forum.XGTZ, { data: { cate_id: deail.cate_id } })
        .then(data => {
          this.relatedList = data.data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
    TZ(id) {
      this.$router.push('/forum/details/' + id);
    },
    //回帖列表
    getReturnCard() {
      if (_.isEmpty(this.deail)) {
        return;
      }
      return this.$request(Apis.forum.HUITIEXINXI, { data: { id: this.deail.id } })
        .then(data => {
          this.returnCardList = data.data.data;
          // this.$nextTick(() => {
          //   setTimeout(() => {
          //     this.returnCardList.forEach(item => {
          //       if (item.content.startsWith('.gif')) {
          //         $('.hf-content p img').css('width', '');
          //       }
          //     });
          //   }, 10);
          // });
        })
        .catch(e => {})
        .finally(() => {});
    },
    btnreport() {
      this.isreport = true;
    },
    handleOkReport() {
      if (_.isEmpty(this.info)) {
        this.$message.warning('请您先去登录');
        return;
      }
      if (_.isEmpty(this.deail)) {
        return;
      }
      return this.$request(Apis.forum.JUBAOS, {
        data: {
          art_id: this.deail.id,
          token: this.info.token,
          content: this.textareaValueReport,
        },
      })
        .then(data => {
          this.getReturnCard();
          this.$message.success('举报成功');
          this.textareaValueReport = '';
          this.isreport = false;
        })
        .catch(e => {})
        .finally(() => {});
    },
    handleCancelReport() {
      this.isreport = false;
    },
  },
};
</script>
<style lang="less">
#content111 {
  p {
    margin: 0;
    padding: 0;

    video,
    img {
      width: 100%;
      display: block;
      margin-top: 5px;
    }
  }
}
.hf-content p img {
  width: 15%;
}
</style>
<style lang="less" scoped>
.forum-details {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 30px;
  .details-article {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid #ccc;
    .article-left {
      width: 20%;
      min-height: 100%;
      background: #404040;
      .item {
        color: #ccc;

        width: 80%;
        margin: auto;
        margin-top: 15px;
        span {
          color: #ffcc00;
        }
      }
      .head-portrait {
        position: relative;
        width: 55%;
        margin: 15px auto;
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: auto;
          overflow: hidden;
          background: #ccc;
        }
      }
    }
    .article-right {
      width: 75%;
      color: #fff;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .top-title {
          font-size: 18px;
          width: 80%;
          display: flex;
          align-items: center;
          p {
            margin: 0;
            padding: 0;
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span {
            font-size: 14px;
            color: #ccc;
            cursor: pointer;

            margin-left: 10px;
          }
        }
        .top-cz {
          width: 20%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .print {
            cursor: pointer;
            font-size: 22px;
          }
          .jump {
            width: 40%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .before,
            .after {
              cursor: pointer;
              font-size: 22px;
            }
          }
        }
      }
      .date {
        color: #ccc;
        cursor: pointer;
        span {
          margin: 0 10px;
        }
        .name {
          color: #ffcc00;
          margin: 0;
        }
      }
      .content {
        letter-spacing: 1px;
        color: #ccc;
        font-size: 16px;
        margin-bottom: 50px;
      }

      .hf-content {
        width: 100%;
        letter-spacing: 1px;
        color: #ccc;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .score {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        .score-look {
          width: 20%;
          position: relative;
          cursor: pointer;

          .bg {
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            margin: auto;
            overflow: hidden;
            background: #ffcc00;
          }
          .position {
            text-align: center;
            margin: 5px 0;
          }
        }
        .score-list {
          width: 80%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .item {
            width: 16.666666%;
            margin-bottom: 10px;
            .item-bg {
              position: relative;
              width: 100%;
              .img {
                width: 50px;
                height: 50px;
                margin: auto;
                overflow: hidden;
                background: #ccc;
              }
              .position {
                text-align: center;
                margin: 5px 0;
              }
            }
          }
        }
      }
      .operation {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 50%;
        margin: auto;
        div {
          font-size: 16px;
          width: 30%;
          position: relative;
          cursor: pointer;
          a {
            display: block;
            color: #fff;
          }
        }
      }
      .related {
        .related-wen {
          font-size: 18px;
          font-weight: 600;
        }
        .related-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          margin: 20px auto;
          div {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          div:before {
            content: '';
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background: #fff;
            margin-right: 5px;
          }
        }
      }
      .reply {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          font-size: 18px;
          display: flex;
          cursor: pointer;
          align-items: center;
          .anticon {
            color: #ffcc00;
          }
        }
      }
    }
  }
}
</style>