<template>
  <div class="all-score">
    <a-modal :footer="null" :visible="visible" @cancel="handleCancel">
      <div class="all-score-wen">全部评论</div>
      <div class="all-score-list">
        <div class="item">
          <div>积分</div>
          <div>用户名</div>
          <div>时间</div>
          <div style="text-align:right">理由</div>
        </div>
        <div class="item" v-for="item in allLikeList.data" :key="item.id">
          <div>+{{item.jifen}}</div>
          <div>{{item.username}}</div>
          <div>{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
          <div style="text-align:right">喜欢</div>
        </div>
      </div>
      <div class="all-score-num" v-if="allLikeList.num">总计: {{allLikeList.num}} 分</div>
      <div class="all-score-num" v-else>总计: 0 分</div>
    </a-modal>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: VueTypes.bool.def(false),
    allLikeList: VueTypes.object,
  },
  data() {
    return {};
  },
  methods: {
    handleCancel(e) {
      this.$emit('change', false);
    },
  },
};
</script>
<style lang="less" scoped>
.ant-modal {
  .all-score-wen {
    font-size: 16px;
    font-weight: 600;
  }
  .all-score-list {
    width: 100%;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    height: 300px;
    overflow-y: auto;
    padding-bottom: 20px;
    .item {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      div {
        width: 25%;
      }
    }
  }
  .all-score-num {
    text-align: right;
    font-weight: 600;
    margin-top: 10px;
  }
}
</style>